import { Tuuid } from "supabase/drizzle/jsonTypes";
import { v4 } from "uuid";
import { z } from "zod";

export function uuidv4(): Tuuid {
  return v4() as Tuuid;
}

export const zuuid = z.custom<Tuuid>((val) => {
  return typeof val === "string"
    ? z.string().uuid().safeParse(val).success
    : false;
}, "Not a valid uuid");

export function isUuid(val: string): val is Tuuid {
  return zuuid.safeParse(val).success;
}
